import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Spider Guy Logo" style={{ width: '150px' }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
            <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/services">
                  Services
                </Link>
            
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/services/spider-control-services">
                    Spider Control Services     
                  </Link>
                  <Link className="navbar-item" to="/services/perimeter-pest-control-application">
                    Perimeter Pest Control         
                  </Link>
                  <Link className="navbar-item" to="/services/mosquito-spraying-service">
                    Mosquito Spraying Service     
                  </Link>
                  <Link className="navbar-item" to="/services/spider-guy-additional-services">
                    Additional Services    
                  </Link>       
                </div>   
            </div>  
            <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/locations">
                  Locations
                </Link>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/oakland-county-spider-control-services">
                    Oakland County         
                  </Link>
                  <Link className="navbar-item" to="/macomb-county-spider-control-services">
                    Macomb County         
                  </Link>
                  
                
                </div> 
              </div>  
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-item" to="/spider-identifier">
                  Spider Identifier
                </Link>
              </div>  
                <Link className="navbar-item" to="/get-a-quote">
                  Get A Quote
                </Link>
                <Link className="navbar-item" to="/blog">
                 Articles
                </Link>
                <Link className="navbar-item" to="/contact">
                  Contact Us
                </Link>
            </div>
          
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
